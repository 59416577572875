import { EventsEnum } from '@/constants/enums/EventsEnum';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { fetcher } from '@/lib/tools/fetcher';
import { CropRateModel } from '@/models/cropRate/CropRateModel';
import { FieldAverageIndexModel } from '@/models/field/FieldAverageIndexModel';
import { FieldModel } from '@/models/field/FieldModel';
import CropRotationEvents from '@/modules/cropRotation/CropRotationEvents';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import PermissionsList from '@/modules/permissions/PermissionsList';
import ApiService from '@/services/api/ApiService';
import { FieldFeatureDto } from '@/services/api/dto/gis/FieldFeatureDto';
import { AverageIndexDto } from '@/services/api/dto/monitoring/AverageIndexDto';
import EventBus from '@/services/eventBus/EventBus';
import LoadingStatus from '@/services/loading/LoadingStatus';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import {
  computed, reactive, Ref, ref, UnwrapRef,
} from 'vue';
import { FileIndexKindEnums } from '@/constants/enums/FileIndexKindEnums';

class FieldsList {
  get fields(): Array<UnwrapRef<FieldModel>> {
    return this._fields;
  }

  get structBounds(): Ref<mapboxgl.LngLatBounds | undefined> {
    return this._structBounds;
  }

  blockChange = computed({
    get: () => this._blockChange.value,
    set: (v) => {
      this._blockChange.value = v;
    },
  })

  private _fields = reactive<FieldModel[]>([]);

  private _structBounds = ref<LngLatBounds>();

  private _blockChange = ref(false);

  private status = {
    download: {
      cropRate: [] as number[],
    },
  }

  constructor() {
    FieldsEvents.onChangeActiveField((fieldId: number | undefined) => {
      // this._activeField.value = fieldId ? this.findFieldById(fieldId) : undefined;
      // if (this._activeField.value) {
      //   EventBus.$emit(EventsEnum.FieldSelectorActiveFieldSet, this._activeField.value);
      // }
    });
    CropRotationEvents.onSelectActiveSeason((season) => {
      FieldsEvents.emitFeatureUpdated();
    });
  }

  findFieldById(id: number | string): FieldModel | undefined {
    return this._fields.find((f) => f.id === Number(id)) as FieldModel | undefined;
  }

  async fetchFields(structId: number, force = false) {
    return fetcher(LoadingNamesEnum.FIELDS_LIST, 'default', force, async () => {
      // const activeFieldId = this.activeFieldId;
      this._fields.splice(0, this._fields.length);
      const { data } = await ApiService.gis.getUnitFields({
        unitId: structId,
      });
      data.features.forEach((feature: FieldFeatureDto) => {
        this._fields.push(new FieldModel(feature));
      });
      LoadingStatus.none(LoadingNamesEnum.ALL_BASE_TASK);
      LoadingStatus.none(LoadingNamesEnum.CROP_RATES);
    });
  }

  getFieldModel(field: string | number | FieldModel | undefined): FieldModel | undefined {
    if (typeof field === 'string' || typeof field === 'number') {
      return this.findFieldById(field);
    }
    if (typeof field === 'object') {
      return field as FieldModel;
    }
    return undefined;
  }

  async fetchCropRates(force = false) {
    if (LoadingStatus.isNone.value(LoadingNamesEnum.CROP_RATES) || force) {
      LoadingStatus.loading(LoadingNamesEnum.CROP_RATES);
      try {
        const { data } = await ApiService.cropRate.getFieldsCropRate();
        this._fields.forEach((f) => f.cropRates.splice(0, f.cropRates.length));
        data.forEach((dto) => {
          const field = this.getFieldModel(dto.field);
          if (field) {
            field.cropRates.push(new CropRateModel(dto));
          }
        });

        LoadingStatus.success(LoadingNamesEnum.CROP_RATES);
      } catch (e) {
        LoadingStatus.error(LoadingNamesEnum.CROP_RATES, e);
      }
    }
  }

  async fetchAllBaseTask(structId: number, force = false) {
    return fetcher(LoadingNamesEnum.ALL_BASE_TASK, 'default', force, async () => {
      const { data } = await ApiService.taskMap.getAllTasks(structId);

      data.forEach((a) => {
        if (a) {
          this.getFieldModel(a.id)?.updateBaseTaskMap(a.task_list);
        }
      });
    });
  }

  removeField(id: number) {
    this._fields.splice(this._fields.findIndex((a) => a.id === id), 1);
  }

  async deleteField(id: number) {
    if (this._fields.some((a) => a.id === id) && PermissionsList.hasPermission('field', 'delete')) {
      LoadingStatus.loading(LoadingNamesEnum.FIELDS_LIST);
      try {
        this.removeField(id);
        await ApiService.gis.deleteField({ id });

        LoadingStatus.success(LoadingNamesEnum.FIELDS_LIST);
      } catch (e) {
        LoadingStatus.error(LoadingNamesEnum.FIELDS_LIST, e);
      }
    }
  }

  async fetchField(id: string | number) {
    await fetcher(LoadingNamesEnum.FIELD, 'default', true, async () => {
      const { data } = await ApiService.struct.getField(parseInt(id.toString(), 0));
      const field = this.getFieldModel(id);
      if (field) {
        field.update(data.geojson);
      } else {
        this.fields.push(new FieldModel(data.geojson));
      }
      EventBus.$emit(EventsEnum.MapRedrawFields);
    });
  }

  async fetchAllNdvi(force = false) {
    await fetcher(LoadingNamesEnum.AVERAGE_INDEX_NDVI, 'default', force, async () => {
      const { data } = await ApiService.monitoring.getAllAvgIndexes('ndvi');
      Object.keys(data).forEach((f) => {
        // @ts-ignore
        this.getFieldModel(f)?.averageIndexes.push(...data[f.toString()].map((a: AverageIndexDto) => new FieldAverageIndexModel(a as AverageIndexDto, FileIndexKindEnums.NDVI)));
      });
    });
  }
}

export default new FieldsList();
