import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { taskMapBaseLayerDef, taskMapBaseSourceDef } from '@/models/map/Layers/defs/TaskMapBaseDef';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';

export class MapLayerTaskMapBaseModel extends MapLayerModel implements IMapLayerModel {
  readonly data: FieldTaskMapBaseModel;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, indexFile: MapInputType) {
    super(mapModel, type, 'task-map-base', indexFile.uuid);
    this.data = indexFile as FieldTaskMapBaseModel;
    this.init();
  }

  async init(): Promise<void> {
    if (!this.data.geojson?.features) {
      await this.data.fetchData();
    }
    if (this._mapModel?.map) {
      this._mapModel.map
        .addSource(this.sourceId, taskMapBaseSourceDef(this.data.geojson?.features))
        .addLayer(taskMapBaseLayerDef(this.sourceId, this.layerId))
        .moveLayer(this.layerId, MapAnchorEnum.TASK_MAP);
      this.layerIds.push(this.layerId);
      this.sourceIds.push(this.sourceId);
      this.setOpacity(this.opacity);
    }
  }
}
