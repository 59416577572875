import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { TechDeviceDto } from '@/services/api/dto/tech/TechDeviceDto';
import { TechJobDto } from '@/services/api/dto/tech/TechJobDto';
import { useStruct } from '@/composables/useStruct';

export const endpoints = {
  getTechDevices: (farmunit: number): string => `/api/v1/tech/${farmunit}/device/`,
  getJobs: (farmunit: number): string => `/api/v1/tech/${farmunit}/job/`,
  getHistory: (farmunit: number): string => `/api/v1/tech/${farmunit}/job/`,
};

export class ApiTech extends ApiHandler {
  public async getTechDevices(): Promise<ApiResponse<TechDeviceDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<TechDeviceDto[]>(endpoints.getTechDevices(unit));
  }

  public async getJobs(fieldId: number): Promise<ApiResponse<TechJobDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<TechJobDto[]>(endpoints.getJobs(unit), {
      field: fieldId,
    });
  }

  public async getHistory(deviceId: number): Promise<ApiResponse<TechJobDto[]>> {
    const unit = useStruct().structId.value;
    const data = {
      device: deviceId,
      is_not_download: false,
    };
    return await this.request({ auth: true }).get<TechJobDto[]>(endpoints.getHistory(unit), data);
  }
}
