import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { FieldModel } from '@/models/field/FieldModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';
import { FieldTaskMapHarvestModel } from '@/models/field/FieldTaskMapHarvestModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { MapModel } from '@/models/map/MapModel';
import { PoiModel } from '@/models/poi/PoiModel';

export class MapContainerModel {
  get helperContent(): string {
    return this._helperContent;
  }

  set helperContent(value: string) {
    this._helperContent = value;
  }

  get additionalMap(): MapModel | undefined {
    return this._additionalMap;
  }

  set additionalMap(value: MapModel | undefined) {
    this._additionalMap = value;
  }

  get informationMode(): 'none' | 'field' | 'poi' {
    return this._informationMode;
  }

  set informationMode(value: 'none' | 'field' | 'poi') {
    this._informationMode = value;
  }

  get maxSelectFields(): number {
    return this._maxSelectFields;
  }

  set maxSelectFields(value: number) {
    this._maxSelectFields = value;
  }

  get filterPoi(): {
    selectPoiGroup: number[];
    searchValue: string;
    keySearch: string | undefined;
    filterKeys: string[]
    } {
    return this._filterPoi;
  }

  set filterPoi(value: {
    selectPoiGroup: number[];
    searchValue: string;
    keySearch: string | undefined;
    filterKeys: string[]
  }) {
    this._filterPoi = value;
  }

  get harvestTaskMap(): FieldTaskMapHarvestModel | undefined {
    return this._harvestTaskMap;
  }

  set harvestTaskMap(value: FieldTaskMapHarvestModel | undefined) {
    const layer = this._mapModel?.getLayer(MapLayerTypeEnum.TASK_MAP_HARVEST);
    if (layer) {
      this._mapModel?.removeLayer(layer.uuid);
    }
    if (value) {
      this._mapModel?.render(value);
    }
    this._harvestTaskMap = value;
  }

  get factTaskMap(): FieldTaskMapFactModel | undefined {
    return this._factTaskMap;
  }

  set factTaskMap(value: FieldTaskMapFactModel | undefined) {
    const layer = this._mapModel?.getLayer(MapLayerTypeEnum.TASK_MAP_FACT);
    if (layer) {
      this._mapModel?.removeLayer(layer.uuid);
    }
    if (value) {
      this._mapModel?.render(value);
    }
    this._factTaskMap = value;
  }

  get baseTaskMap(): FieldTaskMapBaseModel | undefined {
    return this._baseTaskMap;
  }

  set baseTaskMap(value: FieldTaskMapBaseModel | undefined) {
    // зачем это здесь?
    const layer = this._mapModel?.getLayer(MapLayerTypeEnum.TASK_MAP_BASE);
    if (layer) {
      this._mapModel?.removeLayer(layer.uuid);
    }
    if (value) {
      this._mapModel?.render(value);
    }
    this._baseTaskMap = value;
  }

  get workTaskMap(): FieldTaskMapWorkModel | undefined {
    return this._workTaskMap;
  }

  set workTaskMap(value: FieldTaskMapWorkModel | undefined) {
    const layer = this._mapModel?.getLayer(MapLayerTypeEnum.TASK_MAP_WORK);
    if (layer) {
      this._mapModel?.removeLayer(layer.uuid);
    }
    if (value) {
      this._mapModel?.render(value);
    }
    this._workTaskMap = value;
  }

  get activePoi(): PoiModel | undefined {
    return this._activePoi;
  }

  set activePoi(value: PoiModel | undefined) {
    this._activePoi = value;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get fieldsFilter(): (field: FieldModel) => boolean {
    return this._fieldsFilter;
  }

  set fieldsFilter(value: (field: FieldModel) => boolean) {
    this._fieldsFilter = value;
  }

  get activeField(): FieldModel | undefined {
    return this._activeField;
  }

  set activeField(value: FieldModel | undefined) {
    this._activeField = value;
  }

  get hoverField(): FieldModel | undefined {
    return this._hoverField;
  }

  set hoverField(value: FieldModel | undefined) {
    this._hoverField = value;
  }

  get selectedFields(): FieldModel[] {
    return this._selectedFields;
  }

  set selectedFields(value: FieldModel[]) {
    this._selectedFields = value;
  }

  get disabledFields(): FieldModel[] {
    return this._disabledFields;
  }

  set disabledFields(value: FieldModel[]) {
    this._disabledFields = value;
  }

  get mapModel(): MapModel | undefined {
    return this._mapModel;
  }

  set mapModel(value: MapModel | undefined) {
    this._mapModel = value;
  }

  get compareMap(): MapModel | undefined {
    return this._compareMap;
  }

  set compareMap(value: MapModel | undefined) {
    this._compareMap = value;
  }

  get compareMode(): 'single' | 'shuffle' | 'double' {
    return this._compareMode;
  }

  set compareMode(value: 'single' | 'shuffle' | 'double') {
    this._compareMode = value;
  }

  get showMonitoringMenu(): boolean {
    return this._showMonitoringMenu;
  }

  set showMonitoringMenu(value: boolean) {
    this._showMonitoringMenu = value;
  }

  get showHistoryMenu(): boolean {
    return this._showHistoryMenu;
  }

  set showHistoryMenu(value: boolean) {
    this._showHistoryMenu = value;
  }

  get showTaskMapMenu(): boolean {
    return this._showTaskMapMenu;
  }

  set showTaskMapMenu(value: boolean) {
    this._showTaskMapMenu = value;
  }

  get showDownloadTask(): boolean {
    return this._showDownloadTask;
  }

  set showDownloadTask(value: boolean) {
    this._showDownloadTask = value;
  }

  get showPOI(): boolean {
    return this._showPOI;
  }

  set showPOI(value: boolean) {
    this._showPOI = value;
  }

  get showCompare(): boolean {
    return this._showCompare;
  }

  set showCompare(value: boolean) {
    this._showCompare = value;
  }

  get selectState(): 'single' | 'multiple' {
    return this._selectState;
  }

  set selectState(value: 'single' | 'multiple') {
    this._selectState = value;
  }

  private _id;

  private _fieldsFilter: (field: FieldModel) => boolean = () => true;

  private _activeField: FieldModel | undefined = undefined;

  private _hoverField: FieldModel | undefined = undefined;

  private _selectedFields: FieldModel[] = [];

  private _disabledFields: FieldModel[] = [];

  private _mapModel: MapModel | undefined = undefined;

  private _compareMap: MapModel | undefined = undefined;

  private _additionalMap: MapModel | undefined = undefined;

  private _compareMode: 'single' | 'shuffle' | 'double' = 'single';

  private _showMonitoringMenu = true;

  private _showHistoryMenu = true;

  private _showTaskMapMenu = true;

  private _showDownloadTask = true;

  private _showPOI = true;

  private _showCompare = true;

  private _selectState: 'single' | 'multiple' = 'single';

  private _baseTaskMap: FieldTaskMapBaseModel | undefined;

  private _workTaskMap: FieldTaskMapWorkModel | undefined;

  private _factTaskMap: FieldTaskMapFactModel | undefined

  private _harvestTaskMap: FieldTaskMapHarvestModel | undefined;

  private _activePoi: PoiModel | undefined;

  private _maxSelectFields = Infinity;

  private _helperContent = '';

  private _filterPoi: {
    selectPoiGroup: number[],
    searchValue: string,
    keySearch: string | undefined,
    filterKeys: string[],
  } = {
    selectPoiGroup: [],
    searchValue: '',
    keySearch: undefined,
    filterKeys: [],
  }

  private _informationMode: 'none' | 'field' | 'poi' = 'none';

  public fieldSelector = {
    selectionLimit: { active: false, value: 0 },
  }

  constructor(id: MapContainerEnum, props: Record<string, any>) {
    this._id = id;

    this._fieldsFilter = props.fieldsFilter;
    this._showMonitoringMenu = props.showMonitoringMenu;
    this._showHistoryMenu = props.showHistoryMenu;
    this._showTaskMapMenu = props.showTaskMapMenu;
    this._showPOI = props.showPOI;
    this._showDownloadTask = props.showDownloadTask;
    this._showCompare = props.showCompare;
    this._selectState = props.selectState;

    this._mapModel = new MapModel(id);
  }
}
