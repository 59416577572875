import { ConfigDefaultPosition, ConfigDefaultZoom } from '@/assets/data/Config';
import { useApp } from '@/composables/useApp';
import { useMap } from '@/composables/useMap';
import { useUser } from '@/composables/useUser';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerPropertyEnum } from '@/constants/enums/MapLayerPropertyEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { MapLayerConfigType } from '@/constants/types/map/MapLayerConfigType';
import { MapLayerOptionsType } from '@/constants/types/map/MapLayerOptionsType';
import { MapBackingType } from '@/constants/types/MapBackingType';
import MapLayerSpecification from '@/constants/types/mapbox/MapLayerSpecification';
import { getTransformRequest } from '@/lib/map/getTransformRequest';
import { FieldIndexHistoryModel } from '@/models/field/FieldIndexHistoryModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { MapEvents } from '@/models/map/Events/MapEvents';
import { InitStyle } from '@/models/map/Functions/InitStyle';
import MapToolsFunction from '@/models/map/Functions/MapToolsFunction';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerCursorModel } from '@/models/map/Layers/MapLayerCursorModel';
import { MapLayerDrawerModel } from '@/models/map/Layers/MapLayerDrawerModel';
import { MapLayerFieldLoadingModel } from '@/models/map/Layers/MapLayerFieldLoadingModel';
import { MapLayerHistoryIndexModel } from '@/models/map/Layers/MapLayerHistoryIndexModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { MapLayerNirModel } from '@/models/map/Layers/MapLayerNirModel';
import { MapLayerStructViewModel } from '@/models/map/Layers/MapLayerStructViewModel';
import { MapLayerVectorModel } from '@/models/map/Layers/MapLayerVectorModel';
import { MapLayerWorkTaskContourModel } from '@/models/map/Layers/MapLayerWorkTaskContourModel';
import MapLayerConfig from '@/models/map/MapLayerConfig';
import MapLoadImages from '@/models/map/MapLoadImages';
import { MapModelEvents } from '@/models/map/MapModelEvents';
import { Model } from '@/models/Model';
import StructEvents from '@/modules/struct/StructEvents';
import { TaskMapIndexOverlapDto } from '@/services/api/dto/taskMap/TaskMapIndexOverlapDto';
import LoggerService from '@/services/logger/LoggerService';
import mapboxgl, {
  LayerSpecification, LngLat, MapOptions, Point,
} from 'mapbox-gl';
import { reactive } from 'vue';
import { MapLayerRosreestrModel } from '@/models/map/Layers/MapLayerRosreestrModel';

export class MapModel extends Model {
  public drawerLayer: MapLayerDrawerModel | undefined;

  public readonly postfix;

  protected _indexHistoryFileLayer: MapLayerHistoryIndexModel | undefined;

  constructor(container: MapContainerEnum, postfix = '') {
    super();
    useApp().setLoadingScreen('create-map-model', 'Инициализация модели карты');

    this._container = postfix ? `${container}-${postfix}` : container;

    this.postfix = postfix;

    try {
      const mapOptions: MapOptions = {
        container: this._container,
        accessToken: process.env.VUE_APP_MAPBOX_KEY.trim(),
        center: ConfigDefaultPosition,
        zoom: ConfigDefaultZoom,
        boxZoom: false,
        maxPitch: 85,
        transformRequest: getTransformRequest,
        doubleClickZoom: false,
      };
      this._map = new mapboxgl.Map(mapOptions) as mapboxgl.Map;
      this._tools = new MapToolsFunction(this._map as mapboxgl.Map);
      this._mapEvents = new MapEvents(this);
      InitStyle(this._map)
        .then(() => {
          if (this._map) {
            this._map.on('load', () => {
              MapLoadImages(this._map as mapboxgl.Map);
              const { mapStyle } = useMap();
              mapStyle.value?.layers.forEach((layer: LayerSpecification & MapLayerSpecification) => {
                if (!layer.metadata?.is_addon || (layer.metadata?.is_addon && useUser().user.value?.isStaff)) {
                  const underLayer = {
                    id: layer.id,
                    name: layer.metadata?.name_alias || '',
                    img: `/images/${layer.id}.png`,
                    value: `${layer.id}1` as MapBackingType,
                  };
                  this._underLayers.push(underLayer);
                  if (layer.layout?.visibility === 'visible') {
                    this._activeUnderLayer = underLayer;
                  }
                }
              });
              this._mapLoaded = true;
            });
          }
        });
    } catch (e) {
      LoggerService.error(e);
    } finally {
      useApp().setLoadingScreen('create-map-model');
    }
    StructEvents.onChangeStructUnit(() => {
      this.removeStructView();
      this.clearByLayerType([
        MapLayerTypeEnum.NIR_FILE,
        MapLayerTypeEnum.POI,
      ]);
      this._workTaskContourLayers.forEach((l) => l.remove());
    });
  }

  private _reliefMode = false;

  get reliefMode(): boolean {
    return this._reliefMode;
  }

  set reliefMode(value: boolean) {
    this._reliefMode = value;
    if (value) {
      this.map?.addSource('3DViewSource', {
        type: 'raster-dem',
        url: 'https://api.maptiler.com/tiles/terrain-rgb/tiles.json?key=uqDBeAhmJDCB69uYUZb1',
      });
      this.map?.setTerrain({
        source: '3DViewSource',
        exaggeration: 5,
      });
    } else {
      this.map?.setTerrain();
      this.map?.removeSource('3DViewSource');
    }
  }

  private _map: mapboxgl.Map | undefined;

  get map(): mapboxgl.Map | undefined {
    return this._map;
  }

  private _mapLoaded = false;

  get mapLoaded(): boolean {
    return this._mapLoaded;
  }

  private _structViewLayer: MapLayerStructViewModel | undefined;

  get structViewLayer(): MapLayerStructViewModel | undefined {
    return this._structViewLayer;
  }

  private _workTaskContourLayers: MapLayerWorkTaskContourModel[] = [];

  get workTaskContourLayers(): MapLayerWorkTaskContourModel[] {
    return this._workTaskContourLayers;
  }

  set workTaskContourLayers(value: MapLayerWorkTaskContourModel[]) {
    this._workTaskContourLayers = value;
  }

  protected _nirFileLayer: MapLayerNirModel | undefined;

  get nirFileLayer(): MapLayerNirModel | undefined {
    return this._nirFileLayer;
  }

  private _container: string = MapContainerEnum.MAIN_MAP;

  get container(): string {
    return this._container;
  }

  private _events = new MapModelEvents();

  get events(): MapModelEvents {
    return this._events;
  }

  private _tools: MapToolsFunction | undefined;

  get tools(): MapToolsFunction | undefined {
    return this._tools;
  }

  private _mapEvents: MapEvents | undefined;

  get mapEvents(): MapEvents | undefined {
    return this._mapEvents;
  }

  private _vector: MapLayerVectorModel | undefined;

  get vector(): MapLayerVectorModel | undefined {
    return this._vector;
  }

  private _fieldsLoading: MapLayerFieldLoadingModel[] = [];

  get fieldsLoading(): MapLayerFieldLoadingModel[] {
    return this._fieldsLoading;
  }

  set fieldsLoading(value: MapLayerFieldLoadingModel[]) {
    this._fieldsLoading = value;
  }

  private _underLayers: { id: string, name: string, img: string, value: MapBackingType }[] = [];

  get underLayers(): { id: string, name: string; img: string; value: MapBackingType }[] {
    return this._underLayers;
  }

  private _activeUnderLayer: { id: string, name: string, img: string, value: MapBackingType } | undefined;

  get activeUnderLayer(): { id: string, name: string; img: string; value: MapBackingType } | undefined {
    return this._activeUnderLayer;
  }

  set activeUnderLayer(value: { id: string, name: string; img: string; value: MapBackingType } | undefined) {
    if (this.map && value && this.activeUnderLayer) {
      this.map.setLayoutProperty(this.activeUnderLayer.id, 'visibility', 'none');
      this.map.setLayoutProperty(value.id, 'visibility', 'visible');
      this._activeUnderLayer = value;
    }
  }

  private _cursor: MapLayerCursorModel | undefined;

  get cursor(): MapLayerCursorModel | undefined {
    return this._cursor;
  }

  private _cursorPosition: { lngLat: LngLat, point: Point } | undefined = undefined

  get cursorPosition(): { lngLat: LngLat; point: Point} | undefined {
    return this._cursorPosition;
  }

  set cursorPosition(value: {
    lngLat: LngLat;
    point: Point
  } | undefined) {
    this._cursorPosition = value;
  }

  private _allLayers: Array<MapLayerModel & IMapLayerModel> = reactive([]);

  get allLayers(): Array<MapLayerModel & IMapLayerModel> {
    return this._allLayers;
  }

  private _config: MapLayerConfigType[] = MapLayerConfig;

  get config(): MapLayerConfigType[] {
    return this._config;
  }

  renderCursorLayer() {
    this._cursor = new MapLayerCursorModel(this);
  }

  renderHistoryIndexFile(indexFile: FieldIndexHistoryModel): void {
    this.removeHistoryIndexFile();
    this._indexHistoryFileLayer = new MapLayerHistoryIndexModel(this, indexFile);
  }

  removeHistoryIndexFile(): void {
    if (this._indexHistoryFileLayer) {
      // this._indexHistoryFileLayer.remove();
      this._indexHistoryFileLayer = undefined;
    }
  }

  renderWorkTaskContour(workTask: FieldTaskMapWorkModel, settings: Record<string, any>): void {
    this._workTaskContourLayers.find((layer) => layer.workTask.field === workTask.field)?.remove();
    this._workTaskContourLayers.push(new MapLayerWorkTaskContourModel(this, workTask, settings));
  }

  workTaskContourSettings(workTask: FieldTaskMapWorkModel, settings: Record<string, any>, overlap: TaskMapIndexOverlapDto | undefined): void {
    this._workTaskContourLayers.find((layer) => layer.workTask.id === workTask.id)?.setSettings(settings, overlap);
  }

  removeWorkTaskContour(workTask: FieldTaskMapWorkModel): void {
    this._workTaskContourLayers.find((layer) => layer.workTask.id === workTask.id)?.remove();
  }

  renderStructView(): void {
    if (!this._structViewLayer) {
      this._structViewLayer = new MapLayerStructViewModel(this);
    }
  }

  removeStructView(): void {
    if (this._structViewLayer) {
      // this._structViewLayer.remove();
      this._structViewLayer = undefined;
    }
  }

  private _rosreestrLayer;

  renderRosreestr(): void {
    if (!this._rosreestrLayer) {
      this._rosreestrLayer = new MapLayerRosreestrModel(this);
    }
  }

  removeRosreestr(): void {
    if (this._rosreestrLayer) {
      this._rosreestrLayer.remove();
      this._rosreestrLayer = undefined;
    }
  }

  getConfig(dataModel: MapInputType): MapLayerConfigType | undefined {
    return this._config.find((c) => c.input(dataModel as MapInputType));
  }

  /**
   * Возврашаяет  указанные слои
   * принимает MapLayerTypeEnum или uuid (string) - uuid модели слоя или модели данных
   * @param type - MapLayerTypeEnum или uuid слоя
   */
  getLayers(type: MapLayerTypeEnum | MapLayerTypeEnum[] | string[]): (MapLayerModel & IMapLayerModel)[] {
    if (Array.isArray(type)) {
      // @ts-ignore
      return this._allLayers.filter((f) => type.includes(f.uuid) || type.includes(f.data.uuid) || type.includes(f.layerType));
    }
    return this._allLayers.filter((f) => f.layerType === type);
  }

  /**
   * Возврашаяет  указанный слой
   * принимает MapLayerTypeEnum или uuid (string) - uuid модели слоя или модели данных
   * @param value
   */
  // eslint-disable-next-line consistent-return
  getLayer(value: MapLayerTypeEnum | string): (MapLayerModel & IMapLayerModel) | undefined {
    return this._allLayers.find((l) => l.uuid === value || l.data.uuid === value || l.layerType === value);
  }

  render(model: MapInputType, options: MapLayerOptionsType = {}): MapLayerModel & IMapLayerModel | undefined {
    LoggerService.from(LoggerGroupsEnum.MAP_MODEL).log('Пробуем отрисовать модель на карте.');
    const cfg = this.getConfig(model);

    if (cfg) {
      const layer = this.getLayer(cfg?.type);

      LoggerService.from(LoggerGroupsEnum.MAP_MODEL).group('Конфигурация для модели найдена.', cfg);
      if (layer && cfg.properties.includes(MapLayerPropertyEnum.SINGLE)) {
        LoggerService.from(LoggerGroupsEnum.MAP_MODEL).group('Свойства конфигурации указывает что только 1 экземпляр этой модели может отображаться на карте. Удаляем отображение предыдущей модели.', cfg);
        this.removeLayerModel(layer);
      }

      // eslint-disable-next-line new-cap
      const newLayer = new cfg.model(cfg.type, this, model, options);

      this._allLayers.push(newLayer);
      LoggerService.from(LoggerGroupsEnum.MAP_MODEL).group('Модель отрисована на карте.', cfg.layer);
      this._events.emitRenderLayer(newLayer);
      LoggerService.from(LoggerGroupsEnum.MAP_MODEL).log('Отправляем событие \'emitRenderLayer\' в event bus модели карты.', cfg.layer);
      return newLayer;
    }
    if (!model) {
      LoggerService.from(LoggerGroupsEnum.MAP_MODEL).error('Нет модели для отображения: ', model);
    } else {
      LoggerService.from(LoggerGroupsEnum.MAP_MODEL).error('Конфигурация для указаной модели не найдена.', model);
    }
    return undefined;
  }

  /**
   * Удаляет с карты указанный слой или слои
   * принимает MapLayerTypeEnum, MapInputType или uuid (string) - uuid модели слоя или модели данных
   * @param value
   */
  removeLayer(value: MapLayerTypeEnum | MapLayerTypeEnum[] | MapInputType | string | string[]): void {
    if (Array.isArray(value)) {
      if (value.some((v: any) => typeof v === 'string')) {
        this.clearByUuid(value as string[]);
      } else {
        this.clearByLayerType(value as MapLayerTypeEnum[]);
      }
    }
    if (typeof value === 'string') {
      // Если value - строка, считаем, что это UUID
      this.clearByUuid(value);
    } else if (this._config.some((c) => c.input(value as MapInputType))) {
      // Если value соответствует типу MapInputType
      this.clearByInputType(value as MapInputType);
    } else {
      // Если value соответствует типу MapLayerTypeEnum
      this.clearByLayerType(value as MapLayerTypeEnum);
    }
  }

  clearAll(): void {
    this._config.forEach((cfg) => cfg.layer && this.removeLayerModel(cfg.layer));
  }

  removeMap(): void {
    this.clearAll();
    this._map?.remove();
    this._map = undefined;
  }

  private removeLayerModel(layer: MapLayerModel & IMapLayerModel): void {
    const removeLayers = () => {
      layer.layerIds.forEach((id: string) => {
        if (this.map?.getLayer(id) !== undefined) {
          this.map?.removeLayer(id);
        }
      });
    };
    const removeSources = () => {
      layer.sourceIds.forEach((id: string) => {
        this.map?.removeSource(id);
      });
    };
    let counter = 0;
    const interval = setInterval(() => {
      if (counter++ > 50) {
        clearInterval(interval);
      }
      if (layer && layer.layerIds.length > 0 && layer.sourceIds.length > 0) {
        try {
          removeLayers();
          removeSources();
          this._allLayers.splice(0, this._allLayers.length, ...this._allLayers.filter((a: MapLayerModel & IMapLayerModel) => a.uuid !== layer.uuid));
        } catch (e) {
          // empty block
        }
        clearInterval(interval);
      }
    }, 50);
  }

  /**
   * Удаляет с карты все слои по наименованию конфигурации слоя.
   * @param layerType
   */
  private clearByLayerType(layerType: MapLayerTypeEnum | MapLayerTypeEnum[]): void {
    const layerTypes = Array.isArray(layerType) ? layerType : [layerType];
    this._allLayers.forEach((layer) => {
      if (layer.layerType && layerTypes.includes(layer.layerType)) {
        this.removeLayerModel(layer);
      }
    });
  }

  /**
   * Удаляет с карты слой, нарисованный по указанной модели данных
   * @param model
   */
  private clearByInputType(model: MapInputType): void {
    LoggerService.from(LoggerGroupsEnum.MAP_MODEL).log('Clear Model from map.');
    const layer = this._allLayers.find((a) => a.uuid === model.uuid);

    if (layer) {
      this.removeLayerModel(layer);
    } else {
      LoggerService.from(LoggerGroupsEnum.MAP_MODEL).error('Clear config is not found.', model);
    }
  }

  private clearByUuid(uuid: string | string[]): void {
    const uuids = Array.isArray(uuid) ? uuid : [uuid];
    this._allLayers.forEach((l) => {
      if (uuids.includes(l.uuid) || uuid.includes(l.data.uuid)) {
        this.removeLayerModel(l);
      } else {
        LoggerService.from(LoggerGroupsEnum.MAP_MODEL).error('Clear config is not found.', uuid);
      }
    });
  }
}
