<template lang='pug' src='./TaskMapPanel.pug'/>
<style lang='scss' src='./TaskMapPanel.scss'/>

<script lang="ts">
import SkeletonLoader from '@/components/features/SkeletonLoader/SkeletonLoader.vue';
import Content from '@/components/shared/Content/Content.vue';
import SidePanelContent from '@/components/shared/SidePanelContent/SidePanelContent.vue';
import TaskMapCard from '@/components/shared/TaskMapCard/TaskMapCard.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import NoActiveField from '@/components/ui/NoActiveField/NoActiveField.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { useUser } from '@/composables/useUser';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { TaskTypeEnum } from '@/constants/enums/TaskTypeEnum';
import { formatNumber } from '@/lib/convertors/formatNumber';
import { rgbaToHex } from '@/lib/convertors/rgbToHex';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';
import { FieldTaskMapHarvestModel } from '@/models/field/FieldTaskMapHarvestModel';
import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import ViewTaskTabsContent from '@/modules/viewTask/ui/ViewTaskTabsContent/ViewTaskTabsContent.vue';
import ApiService from '@/services/api/ApiService';
import { TaskMapMaterialDto } from '@/services/api/dto/taskMap/TaskMapMaterialDto';
import EventBus from '@/services/eventBus/EventBus';
import LoadingEvents from '@/services/loading/LoadingEvents';
import LoadingStatus from '@/services/loading/LoadingStatus';
import LoggerService from '@/services/logger/LoggerService';
import { formatRuDate } from '@/utils/formatRuDate';
import { getColorZone } from '@/utils/getColorZone';
import { Delete, Download, View } from '@element-plus/icons-vue';
import {
  computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'TaskMapPanel',
  components: {
    SkeletonLoader,
    TaskMapCard,
    SidePanelContent,
    Content,
    ViewTaskTabsContent,
    UiIcon,
    NoActiveField,
  },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      default: MapContainerEnum.MAIN_MAP,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<{ tab: TaskTypeEnum }>,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const { user } = useUser();
    const { activeField, mapModel, sidePanel } = useMapContainers(props.mapContainer);

    // const mapModel = !props.isCompare ? useMapContainers(props.mapContainer).mapModel : useMapContainers(props.mapContainer).compareMap;

    const isStatisticsModal = ref(false);

    const showDeleteModal = ref<FieldTaskMapModel | undefined>();

    const statisticsList = ref<TaskMapMaterialDto[]>([]);

    const statisticsBaseMap = ref<{
      totalArea: number,
      zones: {
      zone: string,
        area: number,
      pctFert: number,
      pctSeed: number,
    }[]}>({
      totalArea: 0,
      zones: [],
    });

    const loading = ref(false);

    const workTaskTab = ref('work-task-map');
    const factTaskTab = ref('fact-task-map');

    const taskMaps = computed<FieldTaskMapModel[]>(() => {
      if (activeField.value) {
        return [...activeField.value.workTaskMaps as FieldTaskMapModel[],
          ...activeField.value.baseTaskMaps as FieldTaskMapModel[],
          ...activeField.value.harvestTaskMaps as FieldTaskMapModel[],
          ...activeField.value.factTaskMaps as FieldTaskMapModel[],
        ];
      }
      return [] as FieldTaskMapModel[];
    });

    const unselect = () => {
      [...activeField.value?.baseTaskMaps || [],
        ...activeField.value?.workTaskMaps || [],
        ...activeField.value?.factTaskMaps || []].forEach((m) => { m.setSelected(false); });
      EventBus.$emit(EventsEnum.ViewTaskMap, undefined, undefined);
    };

    LoadingEvents.onLoadingStart((name, key) => {
      if (name === LoadingNamesEnum.FIELD_TASK_MAPS && key === activeField.value?.id) {
        loading.value = true;
      }
    });

    LoadingEvents.onLoadingEnds((name, key) => {
      if (name === LoadingNamesEnum.FIELD_TASK_MAPS && key === activeField.value?.id) {
        loading.value = false;
      }
    });

    loading.value = !LoadingStatus.isLoaded.value(LoadingNamesEnum.FIELD_TASK_MAPS, activeField.value?.id);

    const findTaskModel = (id: number) => (taskMaps.value || []).find((v) => v.id === id);

    const clearView = () => {
      setTimeout(() => {
        unselect();
        EventBus.$emit(EventsEnum.ViewTaskMap, undefined, undefined);
      }, 50);
    };

    const doView = async (tab: TaskTypeEnum, id: number) => {
      unselect();
      const model = findTaskModel(id);
      if (model) {
        model.setSelected(true);
        switch (tab) {
        case TaskTypeEnum.BASE: EventBus.$emit(EventsEnum.ViewTaskMap, 'base', model.id); break;
        case TaskTypeEnum.WORK: EventBus.$emit(EventsEnum.ViewTaskMap, 'work', model.id); break;
        case TaskTypeEnum.FACT: EventBus.$emit(EventsEnum.ViewTaskMap, 'fact', model.id); break;
        default: LoggerService.error(`Unknown tab type: ${tab}`);
        }
      } else {
        EventBus.$emit(EventsEnum.ViewTaskMap, undefined, undefined);
      }
    };

    const calculateBaseStats = async (model: FieldTaskMapBaseModel) => {
      const arr: {
        zone: string,
        area: number,
        pctFert: number,
        pctSeed: number,
      }[] = [];
      let totalArea = 0;
      await model.fetchData();

      model.geojson.features.forEach((a) => {
        const z = arr.find((r) => r.zone === a.properties.zone);
        if (z) {
          z.area += a.properties.area_ga;
          totalArea += a.properties.area_ga;
        } else {
          totalArea += a.properties.area_ga;
          arr.push({
            zone: a.properties.zone,
            area: a.properties.area_ga,
            pctFert: a.properties.pct_fert,
            pctSeed: a.properties.pct_seed,
          });
        }
      });
      statisticsBaseMap.value.totalArea = totalArea;
      statisticsBaseMap.value.zones = arr;
    };

    const doStats = (id: number) => {
      const model = findTaskModel(id);
      if (model instanceof FieldTaskMapWorkModel) {
        statisticsList.value = model.material || [];
        isStatisticsModal.value = true;
      }
      calculateBaseStats(model as FieldTaskMapBaseModel);

      isStatisticsModal.value = true;
    };

    const doDelete = async () => {
      if (showDeleteModal.value instanceof FieldTaskMapBaseModel) {
        await ApiService.taskMap.deleteTaskMap('base', showDeleteModal.value.id);
      }
      if (showDeleteModal.value instanceof FieldTaskMapWorkModel) {
        await ApiService.taskMap.deleteTaskMap('work', showDeleteModal.value.id);
      }
      if (showDeleteModal.value instanceof FieldTaskMapFactModel) {
        await ApiService.taskMap.deleteTaskMap('fact', showDeleteModal.value.id);
      }
      if (showDeleteModal.value instanceof FieldTaskMapHarvestModel) {
        await ApiService.taskMap.deleteTaskMap('harvest', showDeleteModal.value.id);
      }
      activeField.value?.fetchTaskMaps(true);
      showDeleteModal.value = undefined;
    };

    onMounted(() => {
      FieldsEvents.onChangeActiveField(clearView);
    });

    onUnmounted(() => {
      clearView();
      FieldsEvents.offChangeActiveField(clearView);
    });

    onMounted(() => {
      activeField.value?.fetchTaskMaps();
    });

    watch(activeField, () => {
      mapModel.value?.removeLayer([
        MapLayerTypeEnum.TASK_MAP_WORK,
        MapLayerTypeEnum.TASK_MAP_BASE,
        MapLayerTypeEnum.TASK_MAP_FACT,
        MapLayerTypeEnum.TASK_MAP_HARVEST,
      ]);
      activeField.value?.fetchTaskMaps();
    });

    return {
      taskMaps,
      user,
      activeField,
      TaskTypeEnum,
      formatRuDate,
      doView,
      loading,
      View,
      Download,
      Delete,
      doStats,
      isStatisticsModal,
      statisticsList,
      rgbaToHex,
      getColorZone,
      formatNumber,
      showDeleteModal,
      doDelete,
      workTaskTab,
      factTaskTab,
      sidePanel,
      statisticsBaseMap,
    };
  },
});
</script>
