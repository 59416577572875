<template lang="pug" src="./VectorStandardsBlock.pug"/>
<style lang="scss" src="./VectorStandardsBlock.scss"/>

<script lang="ts">
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { ColorPicker } from 'vue3-colorpicker';
import ShapeStandardList from '@/modules/assets/shapeStandard/ShapeStandardList';
import { ShapeStandardModel } from '@/models/assets/ShapeStandardModel';
import UniqValuesForm from '@/modules/admin/ui/VectorStandardsBlock/UniqValuesForm.vue';
import BorderValuesForm from '@/modules/admin/ui/VectorStandardsBlock/BorderValuesForm.vue';
import { ElNotification } from 'element-plus';
import { BorderValuesType, UniqValuesType } from '@/constants/types/palette/UnifiedVectorPaletteType';
import { tableToExcel } from '@/lib/tools/tableToExcel';

export default defineComponent({
  name: 'VectorStandardsBlock',
  components: {
    BorderValuesForm, UniqValuesForm, ColorPicker, UiDialog,
  },
  setup() {
    const filterRow = ref('');

    const reactiveData = reactive(ShapeStandardList.data);

    const data = computed(() => reactiveData.filter((value) => (filterRow.value.length > 0 ? (value.id.includes(filterRow.value) || value.alias.includes(filterRow.value)) : true)));

    const editRow = ref<ShapeStandardModel | undefined>();

    const rowClick = (row: ShapeStandardModel) => {
      editRow.value = row;
    };

    const create = () => {
      // TODO create form
      ElNotification({
        title: 'Not implemented yet.',
        message: 'Форма добавления пока не сделана. Новые стандарты добавляем в базу ручками.',
        type: 'error',
        duration: 3500,
        position: 'bottom-right',
      });
    };

    const saveBorderValues = (values: BorderValuesType[]) => {
      editRow.value.data = values;
      editRow.value.save();
      editRow.value = undefined;
    };

    const handlerDelete = () => {
      editRow.value.delete();
      editRow.value = undefined;
    };

    const createXlsx = () => {
      tableToExcel('VectorStandardsTable', 'Геошаблоны SHP', 'Геошаблоны SHP');
      // const header = '';
      // const headers = {
      //   Authorization: useAuth().getBearer(),
      //   'Cache-Control': 'no-cache',
      //   'Content-Type': 'application/json',
      //   Pragma: 'no-cache',
      //   Expires: '0',
      // };
      //
      // const hType = (type: string) => {
      //   switch (type) {
      //   case 'uniqValues': return 'Уникальные значения';
      //   case 'borderValues': return 'Естественные границы';
      //   default: return 'Неизвестный тип';
      //   }
      // };
      //
      // const calculateHeadersLength = reactiveData.reduce((acc: number, row: ShapeStandardModel) => {
      //   if (row.data.length > acc) {
      //     acc = row.data.length;
      //   }
      //   return acc;
      // }, 0);
      //
      // const _headers = ['Свойство из shape', 'Наименование', 'Тип стандарта', 'Ед. измерения'];
      //
      // for (let i = 1; i <= calculateHeadersLength; i++) {
      //   _headers.push(`Значение ${i}`);
      //   _headers.push(`Подпись ${i}`);
      //   _headers.push(`Цвет ${i}`);
      // }
      //
      // const _rows = reactiveData.map((row: ShapeStandardModel) => {
      //   const _row = [row.id, row.alias, hType(row.type), row.units];
      //   if (row.type === 'uniqValues') {
      //     (row.data as UniqValuesType[]).forEach((v) => {
      //       _row.push(v.value);
      //       _row.push(v.label);
      //       _row.push(v.color);
      //     });
      //   }
      //   if (row.type === 'borderValues') {
      //     (row.data as BorderValuesType[]).forEach((v) => {
      //       _row.push(`${v.range.from} - ${v.range.to}`);
      //       _row.push(v.label);
      //       _row.push(v.color);
      //     });
      //   }
      //   return _row;
      // });
      //
      // const dataPost = {
      //   file_format: 'xlsx',
      //   headers: _headers,
      //
      //   rows: _rows,
      //   style: {},
      // };
      //
      // fetch(`${process.env.VUE_APP_BASE_URL}/api/v1/etc/tools/table/`, {
      //   method: 'POST',
      //   headers,
      //   body: JSON.stringify(dataPost),
      // })
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     const url = window.URL.createObjectURL(blob);
      //     const link = document.createElement('a');
      //     link.href = url;
      //     link.setAttribute('download', header);
      //     document.body.appendChild(link);
      //     link.click();
      //     document.body.removeChild(link);
      //   });
    };

    const formatValue = (row: UniqValuesType | BorderValuesType) => {
      if ('value' in row) {
        const _row = row as UniqValuesType;
        return `X = ${_row.value}`;
      }
      if ('range' in row) {
        const _row = row as BorderValuesType;
        if (_row.range.from?.toString() === '-Infinity') {
          return `X < ${_row.range?.to}`;
        } if (_row.range.to?.toString() === 'Infinity') {
          return `X >= ${_row.range?.from}`;
        }
        return `${_row.range?.from} >= X < ${_row.range?.to}`;
      }
      return '';
    };

    return {
      filterRow,
      data,
      reactiveData,
      rowClick,
      editRow,
      create,
      saveBorderValues,
      handlerDelete,
      createXlsx,
      formatValue,
    };
  },
});
</script>
