import { Model } from '@/models/Model';
import { PaletteType } from '@/constants/types/palette/PaletteType';
import { BorderValuesType, UniqValuesType } from '@/constants/types/palette/UnifiedVectorPaletteType';
import { ShapeStandardDto } from '@/services/api/dto/assets/shapeStandard/ShapeStandardDto';
import ApiService from '@/services/api/ApiService';
import LoggerService from '@/services/logger/LoggerService';

export class ShapeStandardModel extends Model {
  get units(): string {
    return this._units;
  }

  set units(value: string) {
    this._units = value;
  }

  get data(): UniqValuesType[] | BorderValuesType[] {
    return this._data;
  }

  set data(value: UniqValuesType[] | BorderValuesType[]) {
    this._data = value;
  }

  get alias(): string {
    return this._alias;
  }

  set alias(value: string) {
    this._alias = value;
  }

  get type(): PaletteType {
    return this._type;
  }

  set type(value: PaletteType) {
    this._type = value;
  }

  get id(): string {
    return this._id;
  }

  get rev(): string {
    return this._rev;
  }

  private readonly _id: string;

  private readonly _rev: string | undefined;

  private _type: PaletteType;

  private _alias: string;

  private _units: string;

  private _data: UniqValuesType[] | BorderValuesType[];

  constructor(dto: ShapeStandardDto) {
    super();
    this._id = dto._id;
    this._rev = dto._rev;
    this._alias = dto.alias;
    this._type = dto.type;
    this._units = dto.units;
    this._data = dto.data;
  }

  update(dto: ShapeStandardDto): void {
    if (this._alias !== dto.alias) {
      this._alias = dto.alias;
    }
    if (this._type !== dto.type) {
      this._type = dto.type;
    }
    if (this._type !== dto.type) {
      this._units = dto.units;
    }
    if (JSON.stringify(this._data) !== JSON.stringify(dto.data)) {
      this._data = dto.data;
    }
  }

  toJSON(): ShapeStandardDto {
    const json: ShapeStandardDto = {
      _id: this.id,
      type: this.type,
      units: this.units,
      alias: this.alias,
      data: this.data,
    };
    if (this.rev) {
      json._rev = this.rev;
    }
    return json;
  }

  async save(): Promise<void> {
    await ApiService.assets.insertShapeStandards(this.toJSON());
  }

  async delete(): Promise<void> {
    if (this.rev) {
      await ApiService.assets.destroyShapeStandards(this.id, this.rev);
    } else {
      LoggerService.error('Try to delete shape standard without rev.');
    }
  }
}
