import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "TaskMapSelector" }
const _hoisted_2 = { class: "ph-10" }

export function render(_ctx, _cache) {
  const _component_TaskMapCard = _resolveComponent("TaskMapCard")
  const _component_el_drawer = _resolveComponent("el-drawer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TaskMapCard, {
        "task-map": _ctx.modelValue,
        actions: _ctx.actions,
        onSelectMap: _cache[0] || (_cache[0] = $event => (_ctx.drawer = true)),
        loading: _ctx.loading
      }, null, 8, ["task-map", "actions", "loading"])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: 'body' }, [
      _createVNode(_component_el_drawer, {
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.drawer) = $event)),
        position: _ctx.position,
        title: _ctx.title,
        size: 360
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taskMaps, (_taskMap) => {
              return (_openBlock(), _createBlock(_component_TaskMapCard, {
                "task-map": _taskMap,
                onClickCard: $event => (_ctx.selectTaskMap(_taskMap))
              }, null, 8, ["task-map", "onClickCard"]))
            }), 256))
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "position", "title"])
    ]))
  ], 64))
}