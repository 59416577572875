<template lang='pug' src='./TaskMapSelector.pug'/>
<style lang='scss' src='./TaskMapSelector.scss'/>

<script lang='ts'>
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { defineComponent, PropType, ref } from 'vue';
import { useUser } from '@/composables/useUser';
import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import FieldCard from '@/components/shared/FieldCard/FieldCard.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import FieldsList from '@/modules/fields/FieldsList';
import TaskMapCard from '@/components/shared/TaskMapCard/TaskMapCard.vue';

export default defineComponent({
  name: 'TaskMapSelector',
  props: {
    // Массив рабочих карт
    taskMaps: {
      type: Object as PropType<FieldTaskMapModel[]>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<FieldTaskMapModel | undefined>,
      required: true,
    },
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    actions: {
      type: Array as PropType<string[]>,
      default: ['select-map', 'opacity'] as string[],
    },

    title: {
      type: String,
      default: '',
    },
    position: { // Позиция el-drawer для выбора
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },
    // Флаг ожидания загрузки данных
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    UiDrawer,
    TaskMapCard,
    UiIcon,
    FieldCard,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { user } = useUser();

    const drawer = ref(false);

    const taskMap = ref<FieldTaskMapModel | undefined>();

    const selectTaskMap = (_taskMap: FieldTaskMapModel) => {
      emit('update:modelValue', _taskMap);
      drawer.value = false;
    };

    return {
      drawer,
      taskMap,
      selectTaskMap,
      FieldsList,
    };
  },
});
</script>
