import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-header mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskMapSelector = _resolveComponent("TaskMapSelector")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('base-task-map', 'Базовая карта')), 1),
    (_ctx.activeField )
      ? (_openBlock(), _createBlock(_component_TaskMapSelector, {
          key: 0,
          "task-maps": _ctx.taskMaps,
          modelValue: _ctx.baseTaskMap,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.baseTaskMap) = $event)),
          loading: _ctx.loading,
          "map-container": _ctx.MapContainerEnum.MAIN_MAP,
          position: 'left'
        }, null, 8, ["task-maps", "modelValue", "loading", "map-container"]))
      : _createCommentVNode("", true)
  ], 64))
}