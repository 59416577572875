<template lang='pug' src='./VectorPalettePanel.pug'/>
<style lang='scss' src='./VectorPalettePanel.scss'/>

<script lang="ts">
import UiColorPicker from '@/components/ui/ColorPicker/UiColorPicker.vue';
import UiHistogram from '@/components/ui/Histogram/UiHistogram.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import PaletteGradientColorPicker
  from '@/components/ui/PaletteGradientColorPicker/PaletteGradientColorPicker.vue';
import UiSlider from '@/components/ui/Slider/UiSlider.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { ImageNodeType } from '@/constants/types/ImageNodeType';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import SettingsPalettePanel
  from '@/modules/map/container/SidePanel/ImagesPanel/SettingsPalettePanel.vue';
import BorderValuesTab
  from '@/modules/map/container/SidePanel/ImagesPanel/VectorPalettePanel/BorderValuesTab.vue';
import UniqValuesTab
  from '@/modules/map/container/SidePanel/ImagesPanel/VectorPalettePanel/UniqValuesTab.vue';
import AssetsGradients from '@/services/assets/AssetsGradients';
import {
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';

export default defineComponent({
  name: 'VectorPalettePanel',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    node: {
      type: Object as PropType<ImageNodeType>,
      required: true,
    },
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UiIcon,
    UniqValuesTab,
    BorderValuesTab,
    PaletteGradientColorPicker,
    UiHistogram,
    UiSlider,
    UiColorPicker,
    SettingsPalettePanel,
    UiDrawer,
  },
  emits: ['close', 'save', 'save-name', 'delete-palette'],
  setup(props, { emit }) {
    const vector = reactive<UnifiedVectorModel>(props.node.model as UnifiedVectorModel);

    const activeGradient = ref('');

    const loading = ref(true);

    const mapModel = !props.isCompare ? useMapContainers(props.mapContainer).mapModel : useMapContainers(props.mapContainer).compareMap;

    const uniqueTableData = ref<{value: string | number; color: string; legend: string}[]>([]);

    const layer = ref<MapLayerUnifiedVectorModel | undefined>();

    const findLayer = () => {
      if (!layer.value) {
        layer.value = mapModel.value.allLayers.find((v) => v.data.uuid === vector.uuid && 'palette' in v && v.palette) as MapLayerUnifiedVectorModel | undefined;
        loading.value = !layer.value;
      }
    };

    findLayer();

    watch(() => mapModel.value.allLayers, findLayer, { deep: true });

    onMounted(async () => {
      if (AssetsGradients.data.length > 0) {
        activeGradient.value = AssetsGradients.data[0].id;
      }
      loading.value = !layer.value;
    });

    const close = () => {
      emit('close');

      mapModel.value?.removeLayer(vector.uuid);
      if (props.node.active) {
        const interval = setInterval(() => {
          if (!mapModel.value.map.getLayer(vector.uuid)) {
            (vector as UnifiedVectorModel).render(mapModel.value, props.node.key, props.node.label, props.node.paletteId);
            clearInterval(interval);
          }
        }, 50);
      }
    };

    const save = async () => {
      layer.value?.redraw();
      await layer.value?.palette?.save();
      if (layer.value?.palette?.id !== props.node.paletteId) {
        EventBus.$emit('update-node-palette-id', props.node.key, layer.value?.palette?.id);
        await ApiService.gis.patchGroup(props.node.key, {
          palette: layer.value?.palette?.id,
        });
        await layer.value.fetchPalette(layer.value?.palette?.id);
      }
      close();
    };

    const onColorChange = (event: string, row: {value: string | number; color: string; legend: string}) => {
      if (event.endsWith('ff')) {
        row.color = event.slice(0, 7).toUpperCase();
      } else {
        row.color = event.toUpperCase();
      }
    };

    const redrawMap = () => {
      if (layer.value) {
        layer.value.redraw();
        EventBus.$emit('redraw-legend-table', layer.value.uuid);
      }
    };

    return {
      loading,
      vector,
      layer,
      uniqueTableData,
      save,
      close,
      onColorChange,
      redrawMap,
    };
  },
});
</script>
