<template lang="pug" src="./FieldsPanel.pug"/>
<style lang="scss" src="./FieldsPanel.scss"/>

<script lang="ts">
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import SidePanelContent from '@/components/shared/SidePanelContent/SidePanelContent.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { useStorage } from '@/composables/useStorage';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { FieldModel } from '@/models/field/FieldModel';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import FieldDrawBlock from '@/modules/fields/ui/FieldDrawBlock/FieldDrawBlock.vue';
import PermissionsList from '@/modules/permissions/PermissionsList';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import LoadingStatus from '@/services/loading/LoadingStatus';
import StorageService from '@/services/storage/StorageService';
import {
  Search, Select, SemiSelect, Tools, Upload,
} from '@element-plus/icons-vue';
import {
  genFileId,
  UploadInstance,
  UploadProps,
  UploadRawFile,
  UploadUserFile,
} from 'element-plus';
import {
  computed, defineComponent, onMounted, PropType, ref, Slots, useSlots, watch,
} from 'vue';

type LabelStructKeysType = 'name' | 'name_rus' | 'square';

export default defineComponent({
  name: 'MapFieldsSelector',
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      default: MapContainerEnum.MAIN_MAP,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UiIcon,
    SidePanelContent,
    FieldView,
    Select,
    SemiSelect,
    Tools,
    Upload,
    FieldDrawBlock,
  },
  emits: ['close', 'selected', 'select', 'notfound'],
  setup(props) {
    const slots = useSlots();

    const {
      fields,
      activeField,
      selectedFields,
      hasSelectedField,
      isFieldActive,
      isDisabledField,
      hoverField,
      selectState,
      setHoverField,
      fitField,
      informationMode,
      maxSelectFields,
      cleanSelectedFields,
      selectAllFields,
      mapModel,
      compareMap,
    } = useMapContainers(props.mapContainer);

    const { getMapText } = useStorage();

    const top = ref(0);

    const searchRow = ref('');

    const fieldsListRef = ref();

    const topIndex = ref(0);

    const rowsCount = ref(50);
    const isOpenAddDialog = ref(false);
    const isOpenDraw = ref(false);

    const fileList = ref<UploadUserFile[]>([]);
    const upload = ref<UploadInstance>();

    const labelStruct = ref({
      name: true,
      name_rus: true,
      square: true,
    });

    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: Slots[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type?.toString() !== 'Symbol(Comment)').length > 0;
    };

    const hasNotice = computed(() => hasSlot('notice'));

    const hasIcon = computed(() => hasSlot('icon'));

    const onMouseEnter = (fieldId: number) => {
      setHoverField(fieldId);
    };

    const getLabelStruct = computed(() => (key: LabelStructKeysType) => labelStruct.value[key]);

    const onMouseLeave = () => { setHoverField(undefined); };

    const computedShortFields = computed<{id: number, name: string, sq: number}[]>(() => fields.value.map((f: FieldModel) => ({ id: f.id, name: f.name, sq: f.sq })));

    const blockUploadFile = computed(() => fileList.value?.length === 0);

    const computedFields = computed(() => (searchRow.value.length === 0 ? computedShortFields.value : computedShortFields.value.filter((f) => f.name.indexOf(searchRow.value) !== -1)));

    const slicedFields = computed(() => computedFields.value.slice(topIndex.value, topIndex.value + rowsCount.value + 1));

    const calculateTopIndex = () => {
      topIndex.value = Math.min(
        Math.floor((fieldsListRef.value as HTMLElement).scrollTop / 40),
        Math.abs((computedFields.value.length - rowsCount.value - 1)),
      );
    };
    window.addEventListener('resize', calculateTopIndex);

    const scrollTop = () => { (fieldsListRef.value as HTMLElement).scrollTop = 0; };

    const emitSelectField = (fieldId: number) => {
      FieldsEvents.emitClick(fieldId, props.mapContainer);
      if (selectState.value === 'single') {
        const field = fields.value.find((a) => a.id === fieldId);
        fitField(field, false, 'main');
      }
    };

    const calculateRowsCount = () => {
      if (fieldsListRef.value?.clientHeight > 0) {
        rowsCount.value = Math.ceil(fieldsListRef.value?.clientHeight / 40);
      } else {
        rowsCount.value = 50;
      }
    };

    window.addEventListener('resize', calculateRowsCount);

    const featureClassList = computed(() => (field: FieldModel) => ({
      selected: hasSelectedField(field?.id),
      active: isFieldActive(field?.id),
      selectActive: selectedFields.value.some((a) => a.id === field.id),
      hover: field.id === hoverField.value?.id,
    }));

    watch(fieldsListRef, calculateRowsCount);

    const openAddSHP = () => {
      isOpenAddDialog.value = true;
    };

    const openDrawBlock = () => {
      isOpenDraw.value = true;
    };
    const handleExceed: UploadProps['onExceed'] = (files) => {
      upload.value!.clearFiles();
      const file = files[0] as UploadRawFile;
      file.uid = genFileId();
      upload.value!.handleStart(file);
    };

    const uploadFile = async () => {
      const formData = new FormData();
      // @ts-ignore
      formData.append('file', fileList.value[0].raw);
      // @ts-ignore
      formData.append('farmunit', StructList.activeStruct.value?.id.toString());

      await ApiService.gis.addFields(formData).catch((e) => {
        fileList.value = [];
      });

      fileList.value = [];
      isOpenAddDialog.value = false;
    };

    const save = async () => {
      if (typeof getMapText.value.name !== 'undefined' && !props.isCompare) {
        await StorageService.updateItem(StorageKeyEnum.MAPTEXT, labelStruct.value);
      } else {
        await StorageService.setItem(StorageKeyEnum.MAPTEXT, labelStruct.value);
      }
    };

    const toggleLabelStruct = async (key: LabelStructKeysType) => {
      labelStruct.value[key] = !labelStruct.value[key];
      await save();
      const layer = mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS);
      const layerCompare = compareMap.value?.getLayer(MapLayerTypeEnum.FIELDS);
      if (layer !== undefined) {
        (layer as MapLayerFieldsModel).labelsShow = labelStruct.value;
      }
      if (layerCompare !== undefined) {
        (layerCompare as MapLayerFieldsModel).labelsShow = labelStruct.value;
      }
      FieldsEvents.emitFeatureUpdated();
    };

    onMounted(() => {
      LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, props.mapContainer).then(() => {
        LoadingStatus.awaitLoad(LoadingNamesEnum.STORAGE).then(() => {
          labelStruct.value = getMapText.value;
          const layer = mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS);
          if (layer !== undefined) {
            (layer as MapLayerFieldsModel).labelsShow = labelStruct.value;
            FieldsEvents.emitFeatureUpdated();
          }
        });
      });
      LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, `${props.mapContainer}-compare`).then(() => {
        const layerCompare = compareMap.value?.getLayer(MapLayerTypeEnum.FIELDS);

        if (layerCompare !== undefined) {
          (layerCompare as MapLayerFieldsModel).labelsShow = labelStruct.value;
          FieldsEvents.emitFeatureUpdated();
        }
      });
    });

    return {
      fieldsListRef,
      searchRow,
      activeField,
      selectedFields,
      hasSelectedField,
      featureClassList,
      onMouseEnter,
      onMouseLeave,
      hasNotice,
      hasIcon,
      top,
      Search,
      hoverField,
      emitSelectField,
      selectState,
      isDisabledField,
      fields,
      calculateTopIndex,
      topIndex,
      rowsCount,
      computedFields,
      slicedFields,
      scrollTop,
      informationMode,
      maxSelectFields,
      selectAllFields,
      cleanSelectedFields,
      Select,
      SemiSelect,
      Tools,
      openAddSHP,
      isOpenAddDialog,
      fileList,
      handleExceed,
      uploadFile,
      Upload,
      blockUploadFile,
      isOpenDraw,
      openDrawBlock,
      PermissionsList,
      getLabelStruct,
      toggleLabelStruct,
    };
  },
});
</script>
