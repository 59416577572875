import { NavigationMenuItemType } from '@/constants/types/NavigationMenuItemType';

export const NavigationMenuItems: NavigationMenuItemType[] = [
  {
    name: 'fields',
    placement: 'top',
    children: [
      {
        name: 'crop-rotation',
        icon: 'crop-rotation',
        placement: 'top',
        loader: {
          message: 'Загрузка данных севооборота..',
          name: 'open-crop-rotation',
        },
        route: 'crop-rotation',
      },
      // {
      //   name: 'fields-satellite',
      //   icon: 'satellite',
      //   permission: {
      //     target: 'satelliteconfiguration',
      //     action: 'view',
      //   },
      //   route: 'satellite',
      // },
      {
        name: 'fields-crop-rate',
        icon: 'crop_rate',
        permission: {
          target: 'cropsuitability',
          action: 'view',
        },
        route: 'fields-crop-rate',
      },
      {
        name: 'asf-order',
        icon: 'order_asf',
        route: 'asf-order',
      },
      {
        name: 'task-map',
        icon: 'task-map',
        route: 'task-map-create',
      },
      {
        name: 'ndvi-rating',
        icon: 'indexes-rating',
        route: 'rating-ndvi',
      },
      // {
      //   name: 'integration',
      //   block: 'IntegrationModal',
      //   icon: 'integration',
      // },
    ],
  },
  {
    name: 'settings',
    placement: 'bottom',
    children: [
      {
        name: 'settings-about',
        block: 'AboutProgramBlock',
        icon: 'about',
      },
    ],
  },
];
