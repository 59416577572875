import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-50d2cc1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "LegendTable" }
const _hoisted_2 = { class: "LegendTable-value" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "LegendTable-row",
        key: item.label
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "LegendTable-value-color",
            style: _normalizeStyle(`background-color: ${item.color}`)
          }, null, 4),
          _createElementVNode("div", {
            class: "LegendTable-value-label",
            innerHTML: _ctx.label(item)
          }, null, 8, _hoisted_3)
        ])
      ]))
    }), 128))
  ]))
}