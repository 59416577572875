<template lang='pug' src='./TaskMapCard.pug'/>
<style lang='scss' src='./TaskMapCard.scss'/>

<script lang='ts'>
import { SliderMarks0to100 } from '@/assets/settings/SliderMarks0to100';
import UiLoading from '@/components/ui/Loading/UiLoading.vue';
import { useAuth } from '@/composables/useAuth';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';
import { FieldTaskMapHarvestModel } from '@/models/field/FieldTaskMapHarvestModel';
import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import StructList from '@/modules/struct/StructList';
import LoggerService from '@/services/logger/LoggerService';
import { formatRuDate } from '@/utils/formatRuDate';
import { DataAnalysis, Delete, Download } from '@element-plus/icons-vue';
import {
  computed, defineComponent, PropType, ref, watch,
} from 'vue';
import { MonitoringTaskMapContourModel } from '@/models/monitoring/MonitoringTaskMapContourModel';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { techType } from '@/constants/constants/techTupe';

export default defineComponent({
  name: 'TaskMapCard',
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      default: MapContainerEnum.MAIN_MAP,
    },
    taskMap: {
      type: Object as PropType<FieldTaskMapModel | undefined>,
      required: true,
    },
    /**
     * Кнопки действий доступных в карте - добавляет кнопки в карту, при клике на который
     * отправляется одноименное действие (emit).
     * 'select-map' - выбор карты задания
     * 'stats' - отображение статистики по карте
     * 'download' - скачать карту задания
     * 'delete' - удалить карту задания
     * 'opacity' - устанавить прозрачность
     */
    actions: {
      type: Array as PropType<string[]>,
      default: [] as string[],
    },
    render: {
      type: Boolean,
      default: false,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
    // Флаг ожидания загрузки данных
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    UiLoading,
    UiDialog,
  },
  emits: ['select-map', 'download', 'delete', 'click-card', 'click-name', 'click-preview', 'stats'],
  setup(props, { emit }) {
    const { accessToken } = useAuth();

    const mapModel = !props.isCompare ? useMapContainers(props.mapContainer).mapModel : useMapContainers(props.mapContainer).compareMap;

    const { activeField } = useMapContainers(props.mapContainer);

    const activeTypeTech = ref();

    const opacity = ref(100);
    const isModal = ref(false);

    // Какие действия нужно отобразить в выпадающем меню
    const actionsToCollapse = ['download', 'delete', 'stats'];

    // Массив входных действий, которые должнгы быть в выпадающем меню
    const collapsedActions = computed<string[]>(() => props.actions.filter((v) => actionsToCollapse.includes(v)));

    const findTaskModel = (id: number) => [...activeField.value?.baseTaskMaps || [],
      ...activeField.value?.workTaskMaps || [],
      ...activeField.value?.factTaskMaps || []].find((v) => v.id === id);

    const doDelete = async (id: number) => {
      const model = findTaskModel(id);
      if (model) {
        await model.delete();
        await activeField.value?.fetchTaskMaps(true);
      }
    };

    const isActive = computed(() => mapModel.value?.allLayers.some((v) => (v.data as MonitoringTaskMapContourModel)?.taskMap?.uuid === props.taskMap?.uuid || v.data.uuid === props.taskMap?.uuid));

    const doDownload = () => {
      if (props.taskMap instanceof FieldTaskMapBaseModel) {
        window.location.href = `${process.env.VUE_APP_API_URL}/task-map/${StructList.activeStruct.value?.id}/base/${props.taskMap.id}/download/?access_token=${accessToken.value}`;
      } else if (props.taskMap instanceof FieldTaskMapWorkModel) {
        window.location.href = `${process.env.VUE_APP_API_URL}/task-map/${StructList.activeStruct.value?.id}/work/${props.taskMap.id}/download/${activeTypeTech.value}/?access_token=${accessToken.value}`;
      } else if (props.taskMap instanceof FieldTaskMapFactModel) {
        window.location.href = `${process.env.VUE_APP_API_URL}/task-map/${StructList.activeStruct.value?.id}/fact/${props.taskMap.id}/download/?access_token=${accessToken.value}`;
      } else {
        LoggerService.error('Download curl is not implemented yet for', props.taskMap);
      }
    };

    const toggleRender = () => {
      if (props.render && props.taskMap) {
        const show = !isActive.value;
        if (props.taskMap instanceof FieldTaskMapBaseModel) {
          mapModel.value?.removeLayer(MapLayerTypeEnum.TASK_MAP_BASE);
          if (show) {
            mapModel.value?.render(props.taskMap as FieldTaskMapBaseModel)?.setOpacity(opacity.value);
          }
        }
        if (props.taskMap instanceof FieldTaskMapWorkModel) {
          mapModel.value?.removeLayer(MapLayerTypeEnum.TASK_MAP_WORK);
          if (show) {
            mapModel.value?.render(props.taskMap as FieldTaskMapWorkModel)?.setOpacity(opacity.value);
          }
        }
        if (props.taskMap instanceof FieldTaskMapFactModel) {
          mapModel.value?.removeLayer(MapLayerTypeEnum.TASK_MAP_FACT);
          if (show) {
            mapModel.value?.render(props.taskMap as FieldTaskMapFactModel)?.setOpacity(opacity.value);
          }
        }
        if (props.taskMap instanceof FieldTaskMapHarvestModel) {
          mapModel.value?.removeLayer(MapLayerTypeEnum.TASK_MAP_HARVEST);
          if (show) {
            mapModel.value?.render(props.taskMap as FieldTaskMapHarvestModel)?.setOpacity(opacity.value);
          }
        }
      }
    };

    const clickName = () => {
      emit('click-name');
      toggleRender();
    };

    const clickPreview = () => {
      emit('click-preview');
      toggleRender();
    };

    watch(opacity, () => {
      const layer = mapModel.value?.getLayer(props.taskMap?.uuid);
      if (layer) {
        layer.setOpacity(
          opacity.value,
        );
      }
    });

    return {
      isActive,
      clickName,
      clickPreview,
      doDelete,
      doDownload,
      formatRuDate,
      collapsedActions,
      DataAnalysis,
      Download,
      Delete,
      FieldTaskMapWorkModel,
      opacity,
      SliderMarks0to100,
      mapModel,
      isModal,
      activeTypeTech,
      techType,
    };
  },
});
</script>
