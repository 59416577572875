<template src="./TableInfo.pug" lang="pug"/>
<style src="./TableInfo.scss" lang="scss"/>
<script  lang="ts">
import {
  computed,
  defineComponent, onMounted, PropType, ref, toRefs,
} from 'vue';
import { PointLike } from 'mapbox-gl';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { useUser } from '@/composables/useUser';

export default defineComponent({
  name: 'TableInfo',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    cords: {
      type: Object as PropType<PointLike>,
      required: true,
    },
    mapContainerName: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
  },
  emits: ['update:open'],
  setup(props, { emit }) {
    const { user } = useUser();
    const isStaff = computed(() => user.value.isStaff);

    const { cords } = toRefs(props);

    const calculateCompare0 = ref();

    const calculateData0 = ref();

    const calculateDataMainMap = ref();
    const openDialog = ref(false);
    const mapContainer = ref<MapContainerEnum>();

    const removeDuplicates = (array: Record<string, any>[]) => array.filter((obj, index, self) => index === self.findIndex((t) => (
      t.layer.id === obj.layer.id
    )));

    onMounted(() => {
      if (props.mapContainerName.includes('-compare')) {
        mapContainer.value = props.mapContainerName.replace('-compare', '') as MapContainerEnum;
        calculateDataMainMap.value = removeDuplicates(useMapContainers(mapContainer.value as MapContainerEnum).mapModel.value?.map?.queryRenderedFeatures(cords.value) || []);
        calculateData0.value = useMapContainers(mapContainer.value as MapContainerEnum).compareMap.value?.map?.queryRenderedFeatures(cords.value);
      } else {
        mapContainer.value = props.mapContainerName;
        calculateDataMainMap.value = removeDuplicates(useMapContainers(props.mapContainerName).mapModel.value?.map?.queryRenderedFeatures(cords.value) || []);
        calculateData0.value = useMapContainers(props.mapContainerName).compareMap.value?.map?.queryRenderedFeatures(cords.value);
      }

      openDialog.value = true;
    });
    const closeDialog = () => {
      emit('update:open', false);
    };

    return {
      calculateData0,
      closeDialog,
      calculateCompare0,
      calculateDataMainMap,
      useMapContainers,
      openDialog,
      mapContainer,
      isStaff,
    };
  },
});

</script>
