import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "UniqValuesForm" }
const _hoisted_2 = { class: "mt-24 mr-15" }
const _hoisted_3 = { class: "mt-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, idx) => {
      return (_openBlock(), _createBlock(_component_el_form, {
        inline: "",
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "Цвет" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_color_picker, {
                  format: 'hex4',
                  "disable-alpha": "",
                  pureColor: row.color,
                  "onUpdate:pureColor": ($event: any) => ((row.color) = $event)
                }, null, 8, ["pureColor", "onUpdate:pureColor"])
              ])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_el_form_item, { label: "Значение" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: row.value,
                "onUpdate:modelValue": ($event: any) => ((row.value) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_el_form_item, {
            label: "Подпись",
            style: {"width":"338px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: row.label,
                "onUpdate:modelValue": ($event: any) => ((row.label) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_popconfirm, {
              title: "Удалить палитру?",
              "confirm-button-text": "Удалить",
              "confirm-button-type": "danger",
              width: "200",
              onConfirm: ($event: any) => (_ctx.rows.splice(idx, 1))
            }, {
              reference: _withCtx(() => [
                _createVNode(_component_el_button, {
                  circle: "",
                  type: "danger"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ui_icon, { name: "mdiDeleteOutline" })
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["onConfirm"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_button, {
              circle: "",
              onClick: _ctx.startMove,
              style: {"cursor":"grab"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ui_icon, { name: "mdiDrag" })
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 2
      }, 1024))
    }), 256))
  ]))
}